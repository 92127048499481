.react-chatbot-kit-chat-container {
    width : 800px;
    font-family: system-ui;
    margin-bottom: 30px;
}

.react-chatbot-kit-chat-inner-container {
    min-height: calc(45vh + 70px);
}

.react-chatbot-kit-chat-header {
    display: none;
}

.react-chatbot-kit-chat-input-container {
}

.react-chatbot-kit-chat-message-container {
    min-height: 45vh;
    overflow: auto;
}

.react-chatbot-kit-chat-input {
    font-family: system-ui;
    border-radius: 5px;
    border:none;
}
.react-chatbot-kit-chat-input:focus {
    outline:none;
}

.react-chatbot-kit-chat-input-form {
    display: None;
    padding: 5px;
    border: 1px solid #AAAAAA;
    border-radius: 5px;
}

.react-chatbot-kit-chat-input::placeholder {
}

.react-chatbot-kit-chat-btn-send {
    width : 50px;
    border-radius: 5px;
    margin-left: 5px;
    background-color: #015b97;
}

.react-chatbot-kit-chat-btn-send-icon {
}

.react-chatbot-kit-chat-bot-message-container {
}

.react-chatbot-kit-chat-bot-avatar-container {
}

.react-chatbot-kit-chat-bot-avatar-icon {
}

.react-chatbot-kit-chat-bot-avatar-letter {
}

.react-chatbot-kit-chat-bot-message {
    margin-left: unset;
    width: unset;
    max-width:  60%;
    overflow: auto;
    background-color: #EEEEEE;
    color : #333333;
}
.react-chatbot-kit-chat-bot-message img{
    max-width: 250px;
}
.react-chatbot-kit-chat-bot-message p{
    margin : 0px;
}
.react-chatbot-kit-chat-bot-message-arrow {
    border-right-color: #EEEEEE;
}

.react-chatbot-kit-user-chat-message{
    background-color: #015b97;
    color : #ffffff;
}
.react-chatbot-kit-user-chat-message p{
    margin : 0px;
}
.react-chatbot-kit-user-chat-message-arrow {
    border-left-color: #015b97;
}

.react-chatbot-kit-chat-bot-loading-icon-container {
}

.chatbot-loader-container {
}

#chatbot-loader #chatbot-loader-dot1 {
}

#chatbot-loader #chatbot-loader-dot2 {
}

#chatbot-loader #chatbot-loader-dot3 {
}

.react-chatbot-kit-error {
}

.react-chatbot-kit-error-container {
}

.react-chatbot-kit-error-header {
}

.react-chatbot-kit-error-docs {
}